import React, { Component } from "react";
// import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import "./Header.css"
// import Countdown from 'react-countdown';




class Header extends Component {
  render() {
    if (!this.props.data) return null;

    // const project = this.props.data.project;
    // const github = this.props.data.github;
    const name = this.props.data.name;
    // const description = this.props.data.description;
   
    

    return (
      <header id="home">
        {/* <ParticlesBg type="circle" bg={true}  /> */}

         <nav id="nav-wrap" className="opaque">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

             <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>
{/*
            <li>
              <a className="smoothscroll" href="#resume">
                Resume
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#portfolio">
                Works
              </a>
            </li> */}

            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text transbg">
            <Fade bottom>
              <h1 className="responsive-headline">{name}</h1>
              <ul className="kodrinfo">
              <li>Vi er et selskap utelukkende bestående av senior systemutviklere og tech leads med lang erfaring innen c#/dotnet-utvikling i nettskyen</li>
              <li><b>Mål:</b> Skape størst mulig kundeverdi i samarbeid med kunden.</li>
              <li>Hva kan vi levere?</li>
                  <ul className="disc">
                    <li>dotnet / C# utvikling</li>
                    <li>Cloud baserte løsninger, med ekspertise i Azure</li>
                    <li>Arkitektur, med ekspertise om <i>Event Driven Architecture</i></li>
                    <li>Mikrotjenester & Serverless</li>
                    <li>Søk, med dybdekunskap om Solr</li>
                    <li>Verdiøkning av data (<i>data pipelines</i>)</li>
                    <li>Tjenester med gode og intuitive API'er</li>
                    <li>BizOps, eksempelvis PowerBI</li>
                    <li>DevOps / Observability / Metrics / Insights</li>
                    <li>CI/CD / Pipelines</li>
                    <li>IaC</li>
                    <li>Tech Lead i ett eller flere teams</li>
                  </ul>
                  </ul>
            </Fade>
            {/* <Fade bottom duration={1200}>
            <Countdown 
    timeTillDate="2022 09 29, 11:00 pm" 
    timeFormat="YYYY MM DD, h:mm a" />
            </Fade> */}
            <hr />
            {/* <Fade bottom duration={2000}>
              <ul className="social">
                <a href={project} className="button btn project-btn">
                  <i className="fa fa-book"></i>Project
                </a>
                <a href={github} className="button btn github-btn">
                  <i className="fa fa-github"></i>Github
                </a>
              </ul>
            </Fade> */}
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
